import React from 'react'
import { useInView } from 'react-hook-inview'
import styled from 'styled-components'
import { arno, colors } from '../const'
import { LinkButton } from './partials/LinkButton'
import { Video } from './partials/Video'

const VideoAndText = ({
  type,
  dimensions,
  link,
  text,
  title,
  layout,
  backgroundColor,
  reverse,
  anchor,
  videoUrl,
  isBackgroundVideo,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  const isMobile = dimensions.width < 950
  const isArticle = type === 'article'

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
    >
      <ContentWrapper reverse={reverse}>
        {title || text || link ? (
          <>
            <Left
              className={isArticle && 'article'}
              layout={layout}
            >
              <h2
                dangerouslySetInnerHTML={{ __html: title }}
                className={!videoUrl ? 'no-video' : ''}
              />
              {isMobile && videoUrl && (
                <>
                  <VideoWrapper>
                    {videoUrl && (
                      <Video
                        url={videoUrl}
                        isBackgroundVideo={isBackgroundVideo}
                      />
                    )}
                  </VideoWrapper>
                </>
              )}
              {text}
              {link && LinkButton(link, backgroundColor)}
            </Left>
            <Right layout={layout}>
              {!isMobile && videoUrl && (
                <>
                  <VideoWrapper>
                    {videoUrl && (
                      <Video
                        url={videoUrl}
                        isBackgroundVideo={isBackgroundVideo}
                      />
                    )}
                  </VideoWrapper>
                </>
              )}
            </Right>
          </>
        ) : (
          <>
            <Full className={isArticle && 'article'}>
              {videoUrl && (
                <>
                  <VideoWrapper>
                    {videoUrl && (
                      <Video
                        url={videoUrl}
                        isBackgroundVideo={isBackgroundVideo}
                      />
                    )}
                  </VideoWrapper>
                </>
              )}
            </Full>
          </>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default VideoAndText

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.beige};` : ''}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55px 0;
`

const ContentWrapper = styled.div`
  ${(props) => {
    if (props.reverse) {
      return `
              flex-direction: row-reverse;
              ${Left}{
                  margin-left: 55px;
                  @media(max-width: 950px){
                     margin: 0;
                  }
              }

          `
    } else {
      return `
               ${Left}{
                margin-right: 55px;
                @media(max-width: 950px){
                    margin-right: 0;
                }
              }
          `
    }
  }}

  a {
    color: ${colors.green};
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  }
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`
const Left = styled.div`
  ${(props) => {
    switch (props.layout) {
      case '50/50':
        return `
        flex: 50;
        p{
            max-width: 600px;
        }
        `
      case '35/65':
      default:
        return 'flex: 35'
    }
  }}

  &:not(.article) {
    h2 {
      ${arno(60)}
      color: ${colors.green}
		margin: 0 0 20px 0;
      &.no-video {
        ${arno(42)}
      }
    }
    p {
      margin: 0;
      padding: 0;
      ${arno(20)}
      color: ${colors.green}
    }
  }
  &.article {
    h2 {
      ${arno(32)}
      color: ${colors.green};
      margin: 0 0 20px 0;
    }
    p {
      margin: 0;
      padding: 0;
      ${arno(20)}
      color: ${colors.green};
    }
  }
  > a {
    margin-top: 20px;
  }
  @media (max-width: 950px) {
    flex: 0 auto;
  }
`
const Right = styled.div`
  ${(props) => {
    switch (props.layout) {
      case '50/50':
        return 'flex: 50;'
      case '35/65':
      default:
        return 'flex: 65'
    }
  }}
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
`
const Full = styled.div`
  flex: 100;
  &:not(.article) {
    h2 {
      ${arno(60)}
      color: ${colors.green}
		margin: 0 0 20px 0;
      &.no-video {
        ${arno(42)}
      }
    }
    p {
      margin: 0;
      padding: 0;
      ${arno(20)}
      color: ${colors.green}
    }
  }
  &.article {
    h2 {
      ${arno(32)}
      color: ${colors.green};
      margin: 0 0 20px 0;
    }
    p {
      margin: 0;
      padding: 0;
      ${arno(20)}
      color: ${colors.green};
    }
  }
  > a {
    margin-top: 20px;
  }
  @media (max-width: 950px) {
    flex: 0 auto;
    width: 100%;
  }
`

const VideoWrapper = styled.div`
  flex: 0 0 auto;
  height: 100%;
  width: 100%;
    &.wide {
      width: 100%;
    }
  }

  @media (max-width: 950px) {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 23px;
  }

  width: 100%;
  height: auto;
  max-width: unset;
  min-width: unset;
  min-height: unset;
  max-height: unset;
`
