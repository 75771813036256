import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { arno, colors } from '../const'
import useRecipe from '../hooks/useRecipe'
import { SanityParser } from '../modules/controllers/SanityParser'
import IntroImageAndText from '../modules/IntroImageAndText'

const SingleRecipe = ({ Recipe, slug }) => {
  useRecipe(slug.replace('/', ''))
  let parsed = null
  if (Recipe) {
    parsed = SanityParser({
      _type: 'Recipe',
      ...Recipe,
    })
  }

  if (!Recipe) {
    return null
  }
  if (!parsed) return <div>Loading...</div>
  return (
    <Container>
      <IntroImageAndText
        {...{
          title: parsed.title,
          text: parsed.intro,
          image: parsed.image,
          imageObj: parsed.imageObj,
        }}
      />
      <ContentWrapper>
        <Left>
          <h2>Ingredienser</h2>
          {parsed.portions && (
            <p>
              {parsed.portions} porsjon{parsed.portions > 1 && 'er'}
            </p>
          )}
          <IngredientsWrapper>
            {parsed.ingredients &&
              parsed.ingredients.map((ingredient) => {
                return (
                  <li
                    dangerouslySetInnerHTML={{ __html: ingredient.ingredient }}
                    key={ingredient._key}
                  />
                )
              })}
          </IngredientsWrapper>
        </Left>
        <Right>
          <h2>Tilberedning</h2>
          {parsed.preparation}
        </Right>
      </ContentWrapper>
    </Container>
  )
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign(
    {},
    {
      Recipe: state.recipe[decodeURI(ownProps.slug)],
    },
    ownProps
  )
}

export default connect(mapStateToProps, null)(SingleRecipe)

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  flex-direction: column;
  width: 100%;
  a {
    color: ${colors.green};
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  }
`
const ContentWrapper = styled.div`
  position: relative;
  margin-top: 55px;
  z-index: 1;
  max-width: 1366px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  ul {
    padding: 0;
  }
  padding-left: 0;
  h2 {
    color: ${colors.green};
    ${arno(42)}
  }
  p,
  li {
    color: ${colors.green};
    ${arno(20)}
    margin-left: 0;
  }
  @media (max-width: 950px) {
    flex-direction: column-reverse;
  }
`

const Left = styled.div`
  flex: 2;
  background-color: ${colors.beige};
  padding: 10px 25px 10px 30px;
  margin-top: -10px;
  @media (max-width: 950px) {
    margin-top: 10px;
    order: 2;
  }
`
const Right = styled.div`
  flex: 7;
  @media (min-width: 951px) {
    margin-left: 55px;
  }
  @media (max-width: 950px) {
    order: 1;
  }
`

const IngredientsWrapper = styled.ul`
  margin-top: 5px;
  list-style: none;
`
