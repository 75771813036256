import { A } from 'hookrouter'
import React, { useState } from 'react'
import { useInView } from 'react-hook-inview'
import styled from 'styled-components'
import Image from '../components/image'
import { arno, colors } from '../const'
import { SanityParser } from './controllers/SanityParser'

import {
  Category,
  Container as CategoryContainer,
  CategoryWrapper,
  ContentWrapper,
  ImageWrapper,
  ProductInfo as Info,
} from './partials/visuals'

const RecipesList = ({ title, recipes, backgroundColor }) => {
  const [gridLimitState, setGridLimitState] = useState(6)
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
    >
      <ContentWrapper>
        <TopWrapper>
          <Top>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
          </Top>
        </TopWrapper>
        <RecipesListWrapper>
          <Recipes>
            {!recipes && <div>Loading recipes...</div>}
            {!!recipes &&
              Object.keys(recipes)
                .map((key) => {
                  const recipe = recipes[key]
                  const parsed = SanityParser({
                    _type: 'recipeType',
                    ...recipe,
                  })
                  return (
                    <Recipe
                      href={'../oppskrifter/' + parsed.link}
                      key={recipe._id}
                    >
                      <ImageWrapper>
                        <Image
                          alt={title}
                          src={parsed.image}
                          source={parsed.imageObj}
                          width={300}
                        />
                      </ImageWrapper>
                      <Info>
                        <div>{parsed.category}</div>
                        <div>{parsed.title}</div>
                      </Info>
                    </Recipe>
                  )
                })
                .slice(0, gridLimitState)}
          </Recipes>
        </RecipesListWrapper>
      </ContentWrapper>
    </Container>
  )
}

export default RecipesList

const Container = styled(CategoryContainer)`
  padding: 0 0 55px 0;
`

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    color: ${colors.green};
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  }
`
const Top = styled.div`
  max-width: 1366px;
  padding-left: 55px;
  padding-right: 55px;
  margin-top: 40px;
  @media (max-width: 750px) {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 0;
  }
  width: 100%;
  h1 {
    ${arno(60)}
    color: ${colors.green}
        margin: 0 0 20px 0;
  }
  p {
    max-width: 800px;
    margin: 0 0 20px 0;
    padding: 0;
    ${arno(30)}
    color: ${colors.green}
  }
`

const RecipesListWrapper = styled(CategoryWrapper)`
  width: 100%;
`

const Recipes = styled(Category)`
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  flex-wrap: wrap;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`

const Recipe = styled(A)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(33% - 50px);
  text-decoration: none;
  ${ImageWrapper} {
    flex: 1;
    margin-bottom: 0;
  }
  img {
    aspect-ratio: 4/3;
    max-width: unset;
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    object-fit: cover;
  }
  ${Info} {
    height: unset;
    margin-top: 16px;
  }
  @media (max-width: 950px) {
    max-width: 100%;
  }
`
