import React from 'react'
import { useInView } from 'react-hook-inview'
import styled from 'styled-components'
import OptimizedImage from '../components/image'
import { arno, colors } from '../const'
import { Image } from './partials/Image'
import { LinkButton } from './partials/LinkButton'

const ImageAndText = ({
  type,
  dimensions,
  image,
  imageObj,
  link,
  text,
  title,
  layout,
  backgroundColor,
  double,
  secondary_image,
  secondaryImageObj,
  reverse,
  anchor,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  const isMobile = dimensions.width < 950
  const isArticle = type === 'article'

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
    >
      <ContentWrapper reverse={reverse}>
        <Left
          className={isArticle && 'article'}
          layout={layout}
        >
          <h2
            dangerouslySetInnerHTML={{ __html: title }}
            className={!image && 'no-image'}
          />
          {isMobile && image && (
            <>
              <ImageWrapper double={double}>
                <Image
                  alt={title}
                  src={image}
                  source={imageObj}
                  width={1000}
                />
              </ImageWrapper>
              {double && (
                <ImageWrapper2>
                  <OptimizedImage
                    alt={title}
                    src={secondary_image}
                    source={secondaryImageObj}
                    width={1000}
                  />
                </ImageWrapper2>
              )}
            </>
          )}
          {text}
          {link && LinkButton(link, backgroundColor)}
        </Left>
        <Right layout={layout}>
          {!isMobile && image && (
            <>
              <ImageWrapper double={double}>
                <Image
                  alt={title}
                  src={image}
                  source={imageObj}
                  width={1000}
                />
              </ImageWrapper>
              {double && (
                <ImageWrapper2>
                  <OptimizedImage
                    alt={title}
                    src={secondary_image}
                    source={secondaryImageObj}
                    width={1000}
                  />
                </ImageWrapper2>
              )}
            </>
          )}
        </Right>
      </ContentWrapper>
    </Container>
  )
}

export default ImageAndText

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.beige};` : ''}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55px 0;
`

const ContentWrapper = styled.div`
  ${(props) => {
    if (props.reverse) {
      return `
              flex-direction: row-reverse;
              ${Left}{
                  margin-left: 55px;
                  @media(max-width: 950px){
                     margin: 0;
                  }
              }

          `
    } else {
      return `
               ${Left}{
                margin-right: 55px;
                @media(max-width: 950px){
                    margin-right: 0;
                }
              }
          `
    }
  }}

  a {
    color: ${colors.green};
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  }
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`
const Left = styled.div`
  ${(props) => {
    switch (props.layout) {
      case '50/50':
        return `
        flex: 50;
        p{
            max-width: 600px;
        }
        `
      case '35/65':
      default:
        return 'flex: 35'
    }
  }}

  &:not(.article) {
    h2 {
      ${arno(60)}
      color: ${colors.green}
		margin: 0 0 20px 0;
      &.no-image {
        ${arno(42)}
      }
    }
    p {
      margin: 0;
      padding: 0;
      ${arno(20)}
      color: ${colors.green}
    }
  }
  &.article {
    h2 {
      ${arno(32)}
      color: ${colors.green};
      margin: 0 0 20px 0;
    }
    p {
      margin: 0;
      padding: 0;
      ${arno(20)}
      color: ${colors.green};
    }
  }
  > a {
    margin-top: 20px;
  }
  @media (max-width: 950px) {
    flex: 0 auto;
  }
`
const Right = styled.div`
  ${(props) => {
    switch (props.layout) {
      case '50/50':
        return 'flex: 50;'
      case '35/65':
      default:
        return 'flex: 65'
    }
  }}
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
`

const ImageWrapper = styled.div`
  flex: 0 0 auto;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.double
      ? `
        img{
            padding: 10px 10px 0 0 ;
        }
        `
      : ''}
  img {
    display: block;
    margin: 0 auto;
    &.tall {
      height: 100%;
      max-height: 650px;
      width: unset;
      @media (max-width: 750px) {
        max-height: 300px;
      }
    }
    &.wide {
      width: 100%;
    }
  }

  @media (max-width: 950px) {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 23px;
  }
`
const ImageWrapper2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 950px) {
    width: 25%;
    top: 15%;
    right: 5%;
  }
`
