import { navigate, useRoutes } from 'hookrouter'
import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useAnchorScroll } from './hooks/useAnchorScroll'
import { usePageTracking } from './hooks/usePageTracking'
import Page from './templates/Page'
import SearchPage from './templates/SearchPage'
import SingleProduct from './templates/SingleProduct'
import SingleRecipe from './templates/SingleRecipe'
import SingleTip from './templates/SingleTip'

const routes = {
  '/': () => <Page slug="/" />,
  '/oppskrifter/:slug': ({ slug }) => <SingleRecipe slug={slug} />,
  '/tips/:slug': ({ slug }) => <SingleTip slug={slug} />,
  '/produkter/:slug': ({ slug }) => <SingleProduct slug={slug} />,
  '/search/:slug': ({ slug }) => <SearchPage slug={slug} />,
  '/:slug*': ({ slug }) => <Page slug={slug} />,
  '*': () => {
    navigate('/', false)
    return <Page slug="/" />
  },
}

const Routes = () => {
  const dispatch = useDispatch()

  const hookedRoutes = useRoutes(routes)

  const slug = useMemo(() => {
    return (
      hookedRoutes?.props?.slug ||
      hookedRoutes?.props?.children?.props.slug ||
      (hookedRoutes?.type === Page ? '/' : '')
    )
  }, [hookedRoutes])

  const seoData = useSelector((state) => state.seo?.seoData || {})
  const currentSeoData = seoData[slug] || {
    title: 'Stange Gårdsprodukter',
    description: '',
    image: '',
  }

  usePageTracking()
  useAnchorScroll()

  useEffect(() => {
    if (slug && !seoData[slug]) {
      dispatch({ type: 'seo/fetch', payload: { slug } })
    }
  }, [slug, seoData, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [slug])

  return (
    <>
      <Helmet>
        <title>{currentSeoData.title}</title>
        <meta
          name="description"
          content={currentSeoData.description}
        />
        <meta
          property="og:title"
          content={currentSeoData.title}
        />
        <meta
          property="og:description"
          content={currentSeoData.description}
        />
        <meta
          property="og:image"
          content={currentSeoData.image}
        />
        <meta
          property="og:type"
          content="website"
        />
      </Helmet>
      {hookedRoutes || 'Not Found'}
    </>
  )
}

export default Routes
