import { A } from 'hookrouter'
import React from 'react'
import styled from 'styled-components'
import { arno, colors as color, colors } from '../../../const'

export const circleBorder = (checked) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        fill="none"
        stroke="#707070"
        strokeWidth="2"
      >
        <circle
          cx="11"
          cy="11"
          r="11"
          stroke="none"
        />
        {checked && (
          <circle
            cx="11"
            cy="11"
            r="7"
            fill="#7a1b1f"
          />
        )}
        <circle
          cx="11"
          cy="11"
          r="10"
          fill="none"
        />
      </g>
    </svg>
  )
}

export const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.beige};` : ''}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const FilterWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    >div{
        border: 1px 
        flex: 0 auto;
        margin: 0 auto;
    }
    overflow-y: hidden;

    
`
export const FilterButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  z-index: 10;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
`

export const FilterButton = styled.div`
  background-color: white;
  flex: 1 auto;
  justify-self: auto;
  user-select: none;
  width: calc(100% - 110px);
  border-bottom: 2px solid ${color.lightGrey};
  display: flex;
  justify-content: center;
  z-index: 3;
  div {
    transform: translateY(50%);
    background-color: white;
    flex: 0 auto;
    cursor: pointer;
    text-decoration: none;
    ${arno(22)}
    color: ${color.red};
    border: 1px solid ${color.green};
    border-radius: 50px;
    display: inline-block;
    padding: 5px 25px 5px 20px;
    svg {
      margin-left: 7px;
      transform: rotate(-90deg);
    }

    &:hover svg {
      transform: translateY(-2px) scale(1.1) rotate(-90deg);
    }
  }
  &.hidden svg {
    transform: rotate(90deg);
  }
  &.hidden:hover svg {
    transform: translateY(2px) scale(1.1) rotate(90deg);
  }
`

export const Filter = styled.div`
  display: inline-flex;
  margin: 0 25px 25px 55px;
  ${arno(18)}
  font-weight: 700;
  h3 {
    ${arno(32)}
    margin: 25px 0 10px 0;
  }
  user-select: none;
  transition: all 0.25s;
  flex-direction: column;
  > div {
    flex: 0 auto;
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;
  }
  @media (max-width: 750px) {
    width: 100%;
    > div {
      flex-direction: column;
    }
  }
`
export const Filters = styled.div`
    flex: 0 auto;
    transition: transform .3s ease-in-out, margin .3s ease-in-out;
    margin-top: 55px;
   
    &.hidden{   
        overflowY: hidden;
        transform: translateY(-150%);
        margin-bottom: -100%;
        @media(max-width: 950px){
            margin-bottom: -200%;
        }
    }

    }
    &:not(.hidden){
        
    }
    display: inline-flex;
    justify-content: space-evenly;
    flex-wrap: wrap;    
  
`

export const CheckedWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-right: 55px;
  svg {
    margin-right: 5px;
  }
  @media (max-width: 950px) {
  }
`

export const CategoryWrapper = styled.div`
  &:nth-last-child(2n) {
    ${(props) =>
      (props.type === 'product' || props.type === 'recipe') &&
      `background-color: ${colors.beige};`}
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0;
`

export const Category = styled.div`
  width: 100%;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  h2 {
    ${arno(60)}
    margin-bottom: 20px;
  }
  p {
    ${arno(18)}
    max-width: 700px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  position: relative;
`

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
  }
`

export const ProductInfo = styled.div`
  flex: 0 auto;
  height: 100%;
  width: 90%;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    color: ${colors.green};
    ${arno(18)}
  }
  div:nth-child(2) {
    ${arno(24)}
    text-decoration: underline;
  }
  div:nth-child(3) {
    ${arno(18)}
  }
`
export const Product = styled(A)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  min-width: calc(100% / 3);
  max-width: calc(100% / 3);
  margin-bottom: 50px;
  @media (max-width: 950px) {
    min-width: unset;
    max-width: unset;
    flex: 0 auto;
    ${ProductInfo} {
      margin-top: 10px;
    }
  }
`
export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  img {
    flex: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 225px;
  }
  margin-bottom: 20px;
`

export const Label = styled.div`
  position: absolute;
  top: -10%;
  right: 10%;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  background: ${colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: ${colors.white};
  ${arno(27)}
  font-weight: 600;
`
