import React from 'react'
import { useInView } from 'react-hook-inview'
import styled from 'styled-components'
import { arno, colors } from '../const'

const RichTextAndSidebar = ({
  image,
  link,
  text,
  title,
  sidebar,
  backgroundColor,
  anchor,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
      id={anchor}
    >
      <ContentWrapper>
        {sidebar ? (
          <Content>
            <h2>{title}</h2>
            <RichText>{text}</RichText>
          </Content>
        ) : (
          <ContentNoBar>
            <h2>{title}</h2>
            <RichText>{text}</RichText>
          </ContentNoBar>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default RichTextAndSidebar

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.beige};` : ''}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1366px;
`

const Content = styled.div`
    display: flex;
    margin-left: 55px;
    margin-right: 55px;
    @media(max-width: 750px){
        margin-left: 25px;
        margin-right: 25px;
    }
    flex-wrap: wrap;
    h2{
        margin-top: 0;
        margin-right: 20px
        flex: 30;
        ${arno(42)};        
        color: ${colors.green};
        min-width: 280px;
    }
    @media(max-width: 750px){
        flex-direction: column;
    }
`
const ContentNoBar = styled.div`    
    margin-left: 255px;
    margin-right: 255px;
    h2{
        margin-top: 0;
        ${arno(32)}; 
        color: ${colors.green};
    }
    h2,p{
       margin-left: 15px; 
    } 
 }
}
`

const RichText = styled.div`
  margin: 0;
  flex: 70;
  a,
  p {
    margin-top: 0;
    ${arno(20)}
  }
  a {
    color: ${colors.green};
  }
  p {
    color: ${colors.green};
  }
  h1 {
    ${arno(42)};
  }
  h2 {
    ${arno(32)};
  }
  h3 {
    ${arno(24)};
  }
  figure {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
`
