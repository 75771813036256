import { A } from 'hookrouter'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import OptimizedImage from '../components/image'
import { arno, colors } from '../const'
import useProduct from '../hooks/useProduct'
import ProductGrid from '../modules/ProductGrid'
import { SanityParser } from '../modules/controllers/SanityParser'
import { Image } from '../modules/partials/Image'
import {
  Category,
  ProductInfo as Info,
  ImageWrapper as RecipeImageWrapper,
} from '../modules/partials/visuals'

const i = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25.087"
    viewBox="0 0 24 25.087"
  >
    <g
      id="Group_49"
      data-name="Group 49"
      transform="translate(-83 -615)"
    >
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="12"
        cy="12"
        r="12"
        transform="translate(83 615)"
        fill="#7a1b1f"
      />
      <text
        id="i"
        transform="translate(92.257 633.087)"
        fill="#F3F1EC"
        fontSize="23"
        fontFamily="ArnoPro-Regular, Arno Pro"
      >
        <tspan
          x="0"
          y="0"
        >
          i
        </tspan>
      </text>
    </g>
  </svg>
)

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const RecipesComponent = ({ title, recipes, className }) => {
  if (!recipes) return null
  return (
    <RecipesWrapper className={className}>
      <h2>Oppskrifter med {title}</h2>
      <Recipes>
        {recipes.map((recipe, key) => {
          return (
            <Recipe
              href={'../oppskrifter/' + recipe.link}
              key={recipe._id}
            >
              <RecipeImageWrapper>
                <Image
                  alt={recipe.title}
                  src={recipe.image}
                  source={recipe.imageObj}
                  width={300}
                  height={225}
                />
              </RecipeImageWrapper>
              <Info>
                <div>{recipe.category.title}</div>
                <div>{recipe.title}</div>
              </Info>
            </Recipe>
          )
        })}
      </Recipes>
    </RecipesWrapper>
  )
}

const SingleProduct = ({ productState, slug }) => {
  useProduct(slug.replace('/', ''))
  let parsed = null
  let parsedGrid = null

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 500)

    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  if (!productState) {
    return null
  }

  if (productState) {
    parsed = SanityParser({
      _type: 'product',
      ...productState,
    })
    if (productState.productGrid) {
      parsedGrid = SanityParser({
        _type: 'productGrid',
        ...productState.productGrid,
      })
    }
  }
  const isMobile = dimensions.width < 950

  return (
    <Container>
      {parsed && (
        <ContentWrapper>
          <IntroWrapper>
            <Text>
              <h1>{parsed.title}</h1>
              {isMobile && (
                <ImageWrapper>
                  <Image
                    alt={parsed.title}
                    src={parsed.image}
                    source={parsed.imageObj}
                  />
                </ImageWrapper>
              )}
              {parsed.intro}
              <Allergies>
                <h3>
                  <span>{i}</span>ALLERGENER
                </h3>
                <div>
                  {parsed.allergies &&
                    parsed.allergies.map((item, key) => {
                      let length = parsed.allergies.length
                      if (key < length - 1) {
                        return <div key={key}>{item.title},</div>
                      } else {
                        return <div key={key}>{item.title}</div>
                      }
                    })}
                  {parsed.allergies.length < 1 && <div>Ingen</div>}
                </div>
              </Allergies>
            </Text>
            {!isMobile && (
              <ImageWrapper>
                <Image
                  alt={parsed.title}
                  src={parsed.image}
                  source={parsed.imageObj}
                />
              </ImageWrapper>
            )}
          </IntroWrapper>

          <ProductInfoWrapper>
            <PreparationAndRecipes>
              {parsed.preparation && (
                <>
                  <div>
                    <h2>Tilberedning:</h2>
                    <div>{parsed.preparation}</div>
                  </div>
                  <ImageWrapper>
                    {parsed.preparation_image != null && (
                      <OptimizedImage
                        alt="Preparation Image"
                        src={parsed.preparation_image}
                        source={parsed.preparationImageObj}
                      />
                    )}
                  </ImageWrapper>
                </>
              )}
              <DesktopRecipes
                title={parsed.title}
                recipes={parsed.recipes}
              />
            </PreparationAndRecipes>

            <Section hasPrep={parsed.preparation || parsed.recipes}>
              <NutritionWrapper>
                <h2>NÆRINGSINNHOLD</h2>
                <Energy>
                  <span>PR. 100G</span>
                </Energy>
                <Nutrition key={'key'}>
                  <span>Energi</span>
                  <span>{parsed.energy}</span>
                </Nutrition>
                {parsed.nutritional.map((item, key) => (
                  <Nutrition key={key}>
                    <span>{item.text} </span>
                    <span>{item.gram}g</span>
                  </Nutrition>
                ))}
              </NutritionWrapper>
              <IngredientsWrapper>
                <h2>INGREDIENSER</h2>
                <div>{parsed.ingredients}</div>
              </IngredientsWrapper>
              <ProductInfo>
                <h2>PRODUKTINFORMASJON</h2>
                <div>{parsed.information}</div>
                <MobileRecipes
                  title={parsed.title}
                  recipes={parsed.recipes}
                />
              </ProductInfo>
            </Section>
          </ProductInfoWrapper>
          {parsedGrid && parsedGrid.products.length > 0 && (
            <ProductGrid {...parsedGrid} />
          )}
        </ContentWrapper>
      )}
    </Container>
  )
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign(
    {},
    {
      productState: state.product[decodeURI(ownProps.slug)],
    },
    ownProps
  )
}

export default connect(mapStateToProps, null)(SingleProduct)

const ImageWrapper = styled.div`
  flex: 3;
  align-self: center;
  width: 100%;

  img {
    display: block;
    margin: 0 auto;
    &.tall {
      height: 100%;
      max-height: 400px;
      width: unset;
      @media (max-width: 750px) {
        max-height: 300px;
      }
    }
    &.wide {
      width: 100%;
      height: unset;
      max-width: 650px;
    }
  }
`

const Container = styled.div`
  padding-top: 50px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  width: 100%;
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: calc(100vw - (55px x 2));
  height: 100%;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  display: flex;

  flex-direction: column;
`

const IntroWrapper = styled.div`
  display: flex;
  flex: 1;
  ${ImageWrapper} {
    margin: 55px;
    @media (max-width: 950px) {
      margin-left: 0;
    }
  }
  a {
    color: ${colors.green};
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  }
  padding-bottom: 55px;
  margin-bottom: 55px;
  border-bottom: 1px solid ${colors.lightGrey} @media (max-width: 950px) {
    flex-direction: column;
    width: 100%;
  }
`

const Allergies = styled.div`
  ${arno(18)}
  color: ${colors.green};
  margin-top: 40px;
  h3 {
    display: flex;
    font-weight: 700;
    margin-bottom: 0;
  }
  span {
    margin-right: 5px;
  }
  > div {
    display: flex;
    margin-left: 30px;
    div {
      margin-right: 5px;
    }
  }
`
const Text = styled.div`
  flex: 2;
  h1 {
    ${arno(60)};
  }
  p {
    ${arno(20)};
    margin-right: 30px;
  }
`

const ProductInfoWrapper = styled.div`
  display: flex;
  @media (max-width: 950px) {
    flex-direction: column;
    width: 100%;
  }
`

const PreparationAndRecipes = styled.div`
    flex: 4;
   ${arno(18)};
   margin-right: 30px;
      a {
        color: ${colors.green};
        font-family: arno-pro,serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        line-height: 28px;
      }
    h2{
        ${arno(32)};
        margin-top: 0;
    }
      img{
        max-height: unset;
        max-width: 80%;
        width: 80%;
        height: unset;
        @media(max-width: 950px){

        }
    @media(min-width: 951px){
        margin-right: 125px;
    }
    margin-bottom: 50px;
`

const Section = styled.div`
  flex: 2;
  margin-top: 0;
  ${(props) => {
    if (!props.hasPrep)
      return `
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex: 0 auto;
            justify-content: space-between;
            align-items: space-between;
            align-content: space-between;
            >div{
                max-width: calc((100% / 3) - 55px);
                min-width: 300px;

                flex: 0 auto;
                h2{
                    margin-top: 0;
                }
                border: unset;
                &:last-child{
                    margin-right: 0;
                }
                margin-bottom: 55px;
            }
            @media(max-width: 950px){
                flex-direction: column;
                align-self: flex-start;
                >div{
                    max-width: unset;
                    width: 100%;
                    padding: 0;
                }

            }
        `
  }}
`
const Energy = styled.div`
    border-bottom: 1px solid ${colors.lightGrey}
    margin-bottom: 20px;
    padding-bottom: 5px;
`
const NutritionWrapper = styled.div`
  padding-bottom: 25px;
  ${arno(18)}
  color: ${colors.green};
  h2 {
    margin-top: 0;
    ${arno(30)}
  }
  margin-right: 25px;
  border-bottom: 1px solid ${colors.lightGrey} @media (max-width: 750px) {
    margin-top: 30px;
  }
`

const Nutrition = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  span {
    flex: 1;
  }
  span:last-child {
    text-align: right;
  }
`

const IngredientsWrapper = styled.div`
  h2 {
    ${arno(30)}
  }
  p {
    ${arno(18)}
  }
  padding-bottom: 25px;
  border-bottom: 1px solid ${colors.lightGrey};
`

const ProductInfo = styled.div`
  h2 {
    ${arno(30)}
  }
  p {
    ${arno(18)}
  }
  @media (max-width: 750px) {
    padding-top: 20px;
  }
`

const RecipesWrapper = styled.div`
  margin-top: 55px;
  margin-right: 55px;
`

const Recipes = styled(Category)`
  display: flex;
  gap: 50px;
  margin-left: 0;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`

const Recipe = styled(A)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(50% - 50px);
  text-decoration: none;
  ${ImageWrapper} {
    flex: 1;
    margin-bottom: 0;
  }
  img {
    margin-bottom: 0;
    aspect-ratio: 4/3;
    max-width: unset;
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    object-fit: cover;
  }
  ${Info} {
    margin-top: 16px;
  }
  @media (max-width: 950px) {
    max-width: 100%;
  }
`
const DesktopRecipes = styled(RecipesComponent)`
  @media (max-width: 950px) {
    display: none;
  }
`

const MobileRecipes = styled(RecipesComponent)`
  @media (min-width: 951px) {
    display: none;
  }
`
