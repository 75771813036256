const initialState = {
  seoData: {},
  isLoading: false,
  error: null,
}

const seoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'seo/fetch/start':
      return { ...state, isLoading: true, error: null }

    case 'seo/set': {
      const { slug, data } = action.payload

      if (state.seoData[slug] === data) {
        return state
      }

      return {
        ...state,
        isLoading: false,
        seoData: {
          ...state.seoData,
          [slug]: data,
        },
      }
    }

    case 'seo/fetch/failure':
      return { ...state, isLoading: false, error: action.payload }

    default:
      return state
  }
}

export default seoReducer
