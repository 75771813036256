import { all } from 'redux-saga/effects'

import pageSaga from './pageSaga'
import globalSaga from './globalSaga'
import productSaga from './productSaga'
import recipeSaga from './recipeSaga'
import tipSaga from './tipSaga'
import articleSaga from './articleSaga'
import seoSaga from './seoSaga'
//IMPORT_SAGAS

export default function* () {
  yield all([
    ...productSaga,
    ...recipeSaga,
    ...tipSaga,
    ...pageSaga,
    ...globalSaga,
    ...articleSaga,
    seoSaga(),
    //COMBINE_SAGAS
  ])
}
