import { call, put, takeEvery } from 'redux-saga/effects'
import Api from '../../services/Api'

const api = new Api()

function* fetchSeo({ payload }) {
  const { slug } = payload

  try {
    const response = yield call(api.getSeo, slug)

    if (response) {
      const seoData = {
        title: response.SEO?.title || response.title || 'Stange Gårdsprodukter',
        description:
          response.SEO?.description ||
          response.intro ||
          response.description ||
          '',
        image: response.SEO?.image || response.image || '',
      }

      yield put({
        type: 'seo/set',
        payload: { slug, data: seoData },
      })
    } else {
      yield put({
        type: 'seo/fetch/failure',
        payload: `No data found for slug: ${slug}`,
      })
    }
  } catch (error) {
    yield put({
      type: 'seo/fetch/failure',
      payload: error.message,
    })
  }
}

export default function* seoSaga() {
  yield takeEvery('seo/fetch', fetchSeo)
}
