import BlockContent from '@sanity/block-content-to-react'
import React from 'react'
import Image from '../../components/image'
import imageBuilder from '../../utils/imageBuilder'

export const SanityParser = (module) => {
  // eslint-disable-next-line default-case
  switch (module._type) {
    case 'menu':
      return {
        image: imageBuilder(module.image.asset).url().toString(),
        imageObj: module.image.asset,
        banner_image: imageBuilder(module.banner_image.asset).url().toString(),
        bannerImageObj: module.banner_image.asset,
        links: module.menuItems.map((item) => {
          return {
            ...link(item),
          }
        }),
      }
    case 'footer':
      return {
        image: imageBuilder(module.image.asset).url().toString(),
        imageObj: module.image.asset,
        nav_left: module.footer.nav_left.map((item) => {
          return {
            ...link(item),
          }
        }),
        nav_right: module.footer.nav_right.map((item) => {
          return {
            ...link(item),
          }
        }),
        address: module.contact.address,
        email: module.contact.email,
        phone: module.contact.telephone,
      }
    case 'recipe':
      return {
        image:
          module.image && imageBuilder(module.image.asset).url().toString(),
        imageObj: module.image && module.image.asset,
        image_small:
          module.image &&
          imageBuilder(module.image.asset)
            .quality(80)
            .width(400)
            .url()
            .toString(),
        imageSmallObj: module.image && module.image.asset,
        title: module.title,
        intro: text(module.intro),
        link: module.slug && module.slug.current,
        allergies: module.allergies,
        ingredients: module.ingredients,
        category: module.category.title,
        portions: module.portions,
        preparation: module.preparation && text(module.preparation),
        preparation_image: imageBuilder(module.preparation_image),
        preparationImageObj: module.preparation_image,
        SEO: module.SEO,
      }
    case 'product':
      return {
        image:
          module.image && imageBuilder(module.image.asset).url().toString(),
        imageObj: module.image && module.image.asset,
        image_small:
          module.image &&
          imageBuilder(module.image.asset)
            .quality(80)
            .width(400)
            .url()
            .toString(),
        imageSmallObj: module.image && module.image.asset,
        title: module.title,
        product_name: module.product_name,
        intro: text(module.intro),
        category: module.category && module.category.title,
        weight: module.weight,
        link: module.slug && module.slug.current,
        allergies: module.allergies,
        labels: module.labels,
        proteinTypes: module.proteinTypes,
        information: text(module.information),
        preparation: text(module.preparation),
        preparation_image:
          module.preparation_image &&
          imageBuilder(module.preparation_image).url().toString(),
        preparationImageObj: module.preparation_image,
        ingredients: text(module.ingredients),
        nutritional: module.nutritional,
        energy: module.energy,
        recipes: module.recipes,
        SEO: module.SEO,
      }
    case 'productGrid':
      return {
        title: module.title,
        text: text(module.text),
        products: module.products,
      }
    case 'productFilter':
      return {
        title: module.title,
        text: text(module.text),
        categories: module.categories,
      }
    case 'recipeFilter':
      return {
        title: module.title,
        text: text(module.text),
        categories: module.categories,
      }
    case 'recipesList':
      return {
        title: module.title,
        recipes: module.recipes,
        backgroundColor: module.backgroundColor,
      }
    case 'recipeType': {
      console.log('recipetype yo', module)
      return {
        category: module.category,
        image:
          module.image && imageBuilder(module.image.asset).url().toString(),
        imageObj: module.image && module.image.asset,
        invertedImage:
          module.invertedImage &&
          imageBuilder(module.invertedImage.asset).url().toString(),
        invertedImageObj: module.invertedImage && module.invertedImage.asset,
        slug: module.slug && module.slug.current,
      }
    }
    case 'backgroundImageAndVideo':
      return {
        image: module.image && imageBuilder(module.image).url().toString(),
        media_image:
          module.media &&
          module.media[0]._type !== 'video' &&
          imageBuilder(module.media[0]).url().toString(),
        imageObj:
          module.media && module.media[0]._type !== 'video' && module.media[0],
        video:
          module.media && module.media[0]._type === 'video' && module.media[0],
        link: module.link && link(module.link),
        text: text(module.text),
        title: module.title,
        anchor: module.anchor,
      }
    case 'imageAndText':
      return {
        image: module.image && imageBuilder(module.image).url().toString(),
        imageObj: module.image,
        text: text(module.text),
        title: module.title,
        link: module.link && link(module.link),
        video: module.video,
        reverse: module.reverse,
        layout: module.layout,
        backgroundColor: module.color,
        double: module.double,
        secondary_image:
          module.secondary_image &&
          imageBuilder(module.secondary_image).url().toString(),
        secondaryImageObj: module.secondary_image,
        anchor: module.anchor,
      }
    case 'videoAndText':
      return {
        text: text(module.text),
        title: module.title,
        link: module.link && link(module.link),
        videoUrl: module.video,
        reverse: module.reverse,
        layout: module.layout,
        backgroundColor: module.color,
        anchor: module.anchor,
        isBackgroundVideo: module.isBackgroundVideo === 'true',
      }
    case 'introImageAndText':
      return {
        image: module.image
          ? imageBuilder(module.image).url().toString()
          : false,
        imageObj: module.image ?? undefined,
        title: module.title,
        text: text(module.text),
        image_title: module.image_title,
        image_text: text(module.image_text),
        reverse: module.reverse,
      }
    case 'richTextAndSidebar':
      return {
        title: module.title,
        text: text(module.text),
        sidebar: !module.disable_sidebar,
        anchor: module.anchor,
      }
    case 'textAndText': {
      {
        return {
          text: module.text,
          anchor: module.anchor,
        }
      }
    }
    case 'peopleGrid': {
      return {
        title: module.title,
        people: module.people,
      }
    }
    case 'tipList': {
      return {
        linkArray: module.linkArray,
      }
    }
    case 'proteinTypeFilter': {
      return {
        title: module.title,
        description: module.description,
        proteinTypes: module.proteinTypes,
      }
    }
    case 'proteinType': {
      return {
        image:
          module.image && imageBuilder(module.image.asset).url().toString(),
        imageObj: module.image && module.image.asset,
        invertedImage:
          module.invertedImage &&
          imageBuilder(module.invertedImage.asset).url().toString(),
        invertedImageObj: module.invertedImage && module.invertedImage.asset,
        link: module.link && module.link.slug.current,
      }
    }
    case 'imageLinkAndSidebar': {
      return {
        title: module.title,
        image: module.image
          ? imageBuilder(module.image).url().toString()
          : false,
        sidebar: !module.disable_sidebar,
        link: module.link && link(module.link),
        anchor: module.anchor,
      }
    }
    case 'person': {
      return {
        email: module.email,
        image: module.image
          ? imageBuilder(module.image).url().toString()
          : false,
        name: module.name,
        phone: module.phone,
        title: module.title,
      }
    }
    case 'form': {
      return {
        title: module.title,
        intro: text(module.intro),
        receiver: module.receiver,
        fields: module.fields,
        anchor: module.anchor,
      }
    }
    case 'post':
      return {
        image:
          module.image && imageBuilder(module.image.asset).url().toString(),
        imageObj: module.image && module.image.asset,
        link:
          module.internalLink &&
          link({ internalLink: module.internalLink, _type: 'internalLink' }),
        title: module.title && module.title,
        tagline: module.tagline && module.tagline,
      }
    case 'article':
      return {
        image:
          module.image && imageBuilder(module.image.asset).url().toString(),
        image_small:
          module.image &&
          imageBuilder(module.image.asset)
            .quality(80)
            .width(400)
            .url()
            .toString(),
        title: module.title,
        product_name: module.product_name,
        intro: text(module.intro),
        category: module.category && module.category.title,
        weight: module.weight,
        link: module.slug && module.slug.current,
        allergies: module.allergies,
        information: text(module.information),
        preparation: text(module.preparation),
        preparation_image: imageBuilder(module.preparation_image),
        ingredients: text(module.ingredients),
        nutritional: module.nutritional,
        energy: module.energy,
      }
    case 'page':
      return {
        image:
          module.image && imageBuilder(module.image.asset).url().toString(),
        image_small:
          module.image &&
          imageBuilder(module.image.asset)
            .quality(80)
            .width(400)
            .url()
            .toString(),
        title: module.title,
        product_name: module.product_name,
        intro: text(module.intro),
        category: module.category && module.category.title,
        weight: module.weight,
        link: module.slug && module.slug.current,
        allergies: module.allergies,
        information: text(module.information),
        preparation: text(module.preparation),
        preparation_image: imageBuilder(module.preparation_image),
        ingredients: text(module.ingredients),
        nutritional: module.nutritional,
        energy: module.energy,
      }
  }
}

const text = (text) => {
  if (!text) return
  return (
    <BlockContent
      blocks={text}
      serializers={{
        marks: {
          link: ({ mark, children }) => {
            const { href } = mark
            if (
              href.includes('www') ||
              href.includes('http') ||
              href.includes('mailto')
            ) {
              return (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener"
                >
                  {children}
                </a>
              )
            } else {
              return <a href={href}>{children}</a>
            }
          },
        },
        types: {
          image: (props) => {
            return (
              <figure>
                <Image
                  source={props.node}
                  width={700}
                />
              </figure>
            )
          },
        },
      }}
    />
  )
}

const link = (item) => {
  const link = item[0] ? item[0] : item

  const anchor = link.anchor ? '#' + encodeURI(link.anchor.toLowerCase()) : ''

  switch (link._type) {
    case 'internalLink':
      let sub = ''
      switch (link.internalLink._type) {
        case 'product':
          sub = 'produkter/'
          break
        case 'recipe':
          sub = 'oppskrifter/'
          break
        case 'tip':
          sub = 'tips/'
          break
      }
      return {
        url: sub + link.internalLink.slug.current + anchor,
        name: link.name,
        blank: !!link.blank !== false,
        anchor: !!link.anchor !== false,
      }
    case 'externalLink':
      return {
        url: link.url + anchor,
        name: link.name,
        blank: !!link.blank !== false,
        external: true,
      }
  }
}
