import React from 'react'
import styled from 'styled-components'
import { arno, colors } from '../const'
import { useInView } from 'react-hook-inview'
import Image from '../components/image'

const IntroImageAndText = ({
  image,
  imageObj,
  link,
  text,
  title,
  layout,
  backgroundColor,
  image_title,
  image_text,
  reverse,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
    >
      <ContentWrapper reverse={reverse}>
        <TopWrapper>
          <Top>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div>{text}</div>
          </Top>
        </TopWrapper>
        {image && (
          <ImageWrapper>
            <Image
              alt={title}
              src={image}
              source={imageObj}
            />
            {(image_title || image_text) && (
              <ImageText>
                <h2 dangerouslySetInnerHTML={{ __html: image_title }} />
                <div>{image_text}</div>
              </ImageText>
            )}
          </ImageWrapper>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default IntroImageAndText

const Container = styled.div`
  ${(props) => props.backgroundColor && `background-color: ${colors.beige};`}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`

const ContentWrapper = styled.div`
  ${(props) =>
    props.reverse
      ? `flex-direction: column-reverse;
            ${Top}{
                margin-top: 50px;
                p{
                    ${arno(20)}
                }
            }
            `
      : `flex-direction: column;
             margin-top: 55px;
            `}
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    color: ${colors.green};
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
  }
`
const Top = styled.div`
  max-width: 1366px;
  padding-left: 55px;
  padding-right: 55px;
  margin-top: 40px;
  @media (max-width: 750px) {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 0;
  }
  width: 100%;
  h1 {
    ${arno(60)}
    color: ${colors.green}
        margin: 0 0 20px 0;
  }
  p {
    max-width: 800px;
    margin: 0 0 20px 0;
    padding: 0;
    ${arno(30)}
    color: ${colors.green}
  }
`
const ImageText = styled.div`
  color: white;
  position: absolute;
  bottom: 17%;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  max-width: 800px;
  h2 {
    ${arno(80)}
    margin: 0 0 20px 0;
  }
  p {
    margin: 0 0 20px 0;
    padding: 0;
    ${arno(30)}
  }
  @media (max-width: 950px) {
    h2 {
      ${arno(60)}
    }
    p {
      ${arno(30)}
    }
    bottom: 20px;
  }
`
const ImageWrapper = styled.div`
  flex: 0 auto;
  align-self: center;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`
