import React, { useState } from 'react'
import styled from 'styled-components'
import { arno, colors } from '../const'
import { SanityParser } from './controllers/SanityParser'
import { useInView } from 'react-hook-inview'

const PeopleGrid = ({ text, title, people, backgroundColor }) => {
  const [gridLimitState, setGridLimitState] = useState(40)
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
    >
      <ContentWrapper>
        <IntroWrapper>
          <h2>{title}</h2>
        </IntroWrapper>
        <PeopleWrapper>
          {!people && <div>Loading products...</div>}
          {!!people &&
            Object.keys(people)
              .map((key) => {
                const person = people[key]
                const parsed = SanityParser({
                  _type: 'person',
                  ...person,
                })
                return (
                  <Person key={person._id}>
                    <InfoWrapper>
                      <div>{person.name}</div>
                      <div>{person.title}</div>
                      <a
                        href={'mailto:' + parsed.email}
                        target={'_blank'}
                      >
                        {parsed.email}
                      </a>
                      <div>{person.phone}</div>
                    </InfoWrapper>
                    {parsed.image && (
                      <ImageWrapper>
                        <img
                          alt={title}
                          src={parsed.image}
                        />
                      </ImageWrapper>
                    )}
                  </Person>
                )
              })
              .slice(0, gridLimitState)}
        </PeopleWrapper>
      </ContentWrapper>
    </Container>
  )
}

export default PeopleGrid

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.beige};` : ''}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  flex-wrap: wrap;
`
const ContentWrapper = styled.div`
  z-index: 1;
  height: 100%;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`
const IntroWrapper = styled.div`
  flex: 3;
  margin-top: 50px;
  margin-right: 50px;
  h2 {
    margin-top: 0;
    ${arno(42)};
    color: ${colors.green};
  }
`
const PeopleWrapper = styled.div`
  flex: 7;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Person = styled.div`
  min-width: 300px;
  text-decoration: none;
  flex: 1;
  display: flex;
  align-items: flex-start;
  width: calc(100% / 2);
  max-width: 500px;
  margin-bottom: 50px;
  margin-right: 30px;
  margin-top: 55px;
  @media (max-width: 750px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`
const InfoWrapper = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  a {
    color: ${colors.green};
    ${arno(20)}
  }
  div {
    color: ${colors.green};
  }
  div:nth-child(1) {
    ${arno(20)}
    font-weight: 700;
  }
  div:nth-child(n + 2) {
    ${arno(20)}
  }
  div:nth-child(3) {
    color: ${colors.green};
  }
`
const ImageWrapper = styled.div`
  flex: 1;
  margin-right: 15px;
  img {
    width: 100%;
    height: 100%;
  }
`
