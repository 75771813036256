import BlockContent from '@sanity/block-content-to-react'
import { A } from 'hookrouter'
import React, { useState } from 'react'
import { useInView } from 'react-hook-inview'
import connect from 'react-redux/es/connect/connect'
import styled from 'styled-components'
import Image from '../components/image'
import { arno, colors as color, colors } from '../const'
import { dispatch } from '../redux'
import stripSlug from '../utils/stripSlug'
import { SanityParser } from './controllers/SanityParser'
import { arrow } from './partials/LinkButton'

const circleBorder = (checked) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        fill="none"
        stroke="#707070"
        strokeWidth="2"
      >
        <circle
          cx="11"
          cy="11"
          r="11"
          stroke="none"
        />
        {checked && (
          <circle
            cx="11"
            cy="11"
            r="7"
            fill="#7a1b1f"
          />
        )}
        <circle
          cx="11"
          cy="11"
          r="10"
          fill="none"
        />
      </g>
    </svg>
  )
}
const Search = ({
  type,
  text,
  title,
  categoryState,
  category,
  allergies,
  productState,
  backgroundColor,
  categories,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  switch (type) {
    case 'product':
      dispatch('products/all')
      break
    case 'recipe':
      dispatch('recipes/all')
      break
  }

  const [categoryFilters, setCategoryFilters] = useState(false)

  const toggleCategory = (title) => {
    if (categoryFilters[title]) {
      let tmp = categoryFilters
      delete tmp[title]
      setCategoryFilters({
        ...tmp,
      })
    } else {
      setCategoryFilters({
        ...categoryFilters,
        [title]: true,
      })
    }
  }

  const [allergyFilters, setAllergyFilter] = useState([])

  const toggleAllergy = (title) => {
    setAllergyFilter({
      ...allergyFilters,
      [title]: !allergyFilters[title],
    })
  }

  const [proteinTypeFilters, setProteinTypeFilter] = useState(null)

  const toggleProteinType = (title) => {
    setProteinTypeFilter(title === proteinTypeFilters ? null : title)
  }

  const [filterToggle, setFilterToggle] = useState(true)

  const toggleFilters = () => {
    setFilterToggle(!filterToggle)
  }

  const CategoryPartial = (categories) => {
    return (
      <Filter>
        <div>
          {categories.map((category, i) => {
            return (
              <CheckedWrapper
                onClick={() => toggleCategory(category.title)}
                key={i}
              >
                {circleBorder(categoryFilters[category.title])}
                <div>{category.title}</div>
              </CheckedWrapper>
            )
          })}
        </div>
      </Filter>
    )
  }

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
    >
      <ContentWrapper>
        <FilterWrapper>
          <FilterButtonWrapper>
            <FilterButton className={filterToggle && 'hidden'}>
              <div onClick={() => toggleFilters()}>
                {filterToggle ? 'Filtrer på' : 'Skjul filter'} {arrow}
              </div>
            </FilterButton>
          </FilterButtonWrapper>
          <Filters className={filterToggle && 'hidden'}>
            <h3>Kategori</h3>
            {categoryState &&
              categories &&
              CategoryPartial(categories, categoryState)}
          </Filters>
        </FilterWrapper>
        {categoryState &&
          Object.keys(categories).map((key) => {
            let category = categories[key]
            if (!categoryState[category.slug.current.toLowerCase()]) return
            if (
              categoryFilters !== false &&
              Object.keys(categoryFilters).length &&
              !categoryFilters[category.title]
            )
              return
            return (
              <CategoryWrapper
                type={type}
                key={key}
              >
                <Category id={category.slug.current}>
                  <h2>{category.title}</h2>
                  {category.text && <BlockContent blocks={category.text} />}
                  <ProductsWrapper>
                    {productState &&
                      Object.keys(productState)
                        .filter((key) => {
                          if (
                            productState[key].category.title !== category.title
                          )
                            return false
                          if (productState[key].allergies === undefined)
                            return true

                          if (
                            productState[key]?.proteinTypes?.title ===
                            proteinTypeFilters
                          )
                            return true
                          else if (proteinTypeFilters !== null) return false

                          let test = productState[key].allergies.map(
                            (allergy) => {
                              if (allergyFilters[allergy.title] === true)
                                return false
                            }
                          )
                          return !test.includes(false)
                        })
                        .map((key) => {
                          let product = productState[key]

                          const parsed = SanityParser({
                            _type: type,
                            ...product,
                          })
                          let baseUrl
                          switch (type) {
                            case 'product':
                              baseUrl = 'produkter/'
                              break
                            case 'recipe':
                              baseUrl = 'oppskrifter/'
                              break
                          }
                          return (
                            <Product
                              href={'/' + baseUrl + parsed.link}
                              key={product._id}
                            >
                              <ImageWrapper>
                                <Image
                                  alt={title}
                                  src={parsed.image_small}
                                  source={parsed.imageSmallObj}
                                  width={400}
                                />
                              </ImageWrapper>
                              <ProductInfo>
                                <div>{parsed.category}</div>

                                {type === 'recipe' && <div>{parsed.title}</div>}
                                {type === 'product' && (
                                  <>
                                    <div>{parsed.title}</div>
                                    <div>{parsed.weight}</div>
                                  </>
                                )}
                              </ProductInfo>
                            </Product>
                          )
                        })}
                  </ProductsWrapper>
                </Category>
              </CategoryWrapper>
            )
          })}
      </ContentWrapper>
    </Container>
  )
}

const mapStateToProps = (state, ownProps) => {
  switch (ownProps.type) {
    case 'product':
      return Object.assign(
        {},
        {
          content: state.pages[stripSlug(ownProps.slug)],
          allergies: state.allergy['products'],
          categoryState: state.category['products'],
          productState: state.product,
          proteinTypeState: state.proteinType,
        },
        ownProps
      )
    case 'recipe':
      return Object.assign(
        {},
        {
          content: state.pages[stripSlug(ownProps.slug)],
          categoryState: state.category['recipes'],
          productState: state.recipe,
        },
        ownProps
      )
  }
}
export default connect(mapStateToProps, null)(Search)

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.beige};` : ''}
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const FilterWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    >div{
        border: 1px 
        flex: 0 auto;
        margin: 0 auto;
    }
`
const FilterButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  z-index: 10;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
`

const FilterButton = styled.div`
  background-color: white;
  flex: 1 auto;
  justify-self: auto;
  user-select: none;
  width: calc(100% - 110px);
  border-bottom: 2px solid ${color.lightGrey};
  display: flex;
  justify-content: center;
  z-index: 3;
  div {
    border-radius: 50px;
    transform: translateY(50%);
    background-color: white;
    flex: 0 auto;
    cursor: pointer;
    text-decoration: none;
    ${arno(22)}
    color: ${color.red};
    border: 1px solid ${color.darkGrey};
    display: inline-block;
    padding: 5px 25px 5px 20px;
    svg {
      margin-left: 7px;
      transform: rotate(-90deg);
    }

    &:hover svg {
      transform: translateY(-2px) scale(1.1) rotate(-90deg);
    }
  }
  &.hidden svg {
    transform: rotate(90deg);
  }
  &.hidden:hover svg {
    transform: translateY(2px) scale(1.1) rotate(90deg);
  }
`

const Filter = styled.div`
  display: inline-flex;
  margin: 0 25px 25px 55px;
  ${arno(18)}
  font-weight: 700;
  user-select: none;
  transition: all 0.25s;
  flex-direction: column;
  > div {
    flex: 0 auto;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 12rem;
    width: auto;
    @media (max-width: 600px) {
      flex-direction: column;
      max-height: 100%;
    }
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`

const Filters = styled.div`
    max-width: 1366px;
    display: flex;
    flex-direction: column;
    transition: transform .3s ease-in-out, margin .3s ease-in-out;
    margin-top: 55px;
    width: 100%;
    padding-left: 55px;
    padding-right: 55px;
    h3 {
      ${arno(32)}
      margin: 25px 0 10px 0;
    }
    > div{
      width: fit-content;
      margin: 0 auto 25px auto;
      @media(max-width: 600px){
        margin: 0 0 25px 0;
      }
    }
    &.hidden{   
        overflowY: hidden;
        transform: translateY(-150%);
        margin-bottom: -100%;
        @media(max-width: 950px){
            margin-bottom: -200%;
        }
    }

    }
    &:not(.hidden){
        
    }
    display: inline-flex;
    justify-content: space-evenly;
    flex-wrap: wrap;    
  
`

const CheckedWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-right: 55px;
  svg {
    margin-right: 5px;
  }
  @media (max-width: 950px) {
  }
`

const CategoryWrapper = styled.div`
  &:nth-last-child(2n) {
    ${(props) =>
      (props.type === 'product' || props.type === 'recipe') &&
      `background-color: ${colors.beige};`}
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0;
`

const Category = styled.div`
  width: 100%;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  h2 {
    ${arno(60)}
    margin-bottom: 20px;
  }
  p {
    ${arno(18)}
    max-width: 700px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  position: relative;
`

const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  gap: 25px 50px;
  flex-wrap: wrap;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
  }
  margin-bottom: 50px;
`

const ProductInfo = styled.div`
  max-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    color: ${colors.green};
    ${arno(18)}
  }
  div:nth-child(2) {
    ${arno(24)}
    text-decoration: underline;
  }
  div:nth-child(3) {
    ${arno(18)}
  }
`

const ImageWrapper = styled.div`
  img {
    aspect-ratio: 4/3
    width: 100%;
    height: 100%;
    max-width: 225px;
  }
  margin-bottom: 20px;
`

const Product = styled(A)`
  flex-wrap: wrap;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(33% - 50px);
  text-decoration: none;
  ${ImageWrapper} {
    flex: 1;
    margin-bottom: 0;
  }
  img {
    max-width: unset;
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    object-fit: cover;
  }
  ${ProductInfo} {
    height: unset;
    margin-top: 16px;
  }
  @media (max-width: 950px) {
    width: 100%;
    max-width: 100%;
    ${ProductInfo} {
      margin-top: 10px;
    }
  }
`
