import React, { useState } from 'react'
import { useInView } from 'react-hook-inview'
import styled from 'styled-components'
import { arno, colors } from '../const'

const Field = ({ field, text }) => {
  switch (field) {
    case 'text':
      return (
        <input
          name={text}
          type={'text'}
        />
      )
    case 'textarea':
      return <textarea id={'text'} />
    case 'email':
      return (
        <input
          name={text}
          type={'email'}
        />
      )
    default:
      return
  }
}

const validateEmail = (email) => {
  if (email.length <= 1) return false
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  return re.test(email)
}

const validate = (formData, length) => {
  let allgood = Object.keys(formData).length === length

  Object.keys(formData).map((key) => {
    const data = formData[key]

    switch (data.type) {
      case 'email':
        if (!validateEmail(data.value)) allgood = false
        break
      default:
        if (data.value.length <= 0) allgood = false
    }
  })

  return allgood
}

const send = async (receiver, formData, length) => {
  if (!validate(formData, length)) return false

  let html = '<div>'
  {
    Object.keys(formData).map((key) => {
      const data = formData[key]
      html += '<div>' + data.label + ' : ' + data.value + '</div>'
    })
  }
  html += '</div>'

  try {
    const res = await fetch(
      'https://stange-email-service.vercel.app/api/emails',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ html }),
      }
    )

    if (res.ok) {
      const data = await res.json()
      if (data?.ok === true) {
        return true
      }
    }

    return false
  } catch (error) {
    return false
  }
}

const Form = ({
  intro,
  title,
  receiver,
  fields,
  sidebar,
  backgroundColor,
  anchor,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })

  const [formData, setFormData] = useState([])
  const [formStatus, setFormStatus] = useState(null)

  return (
    <Container
      backgroundColor={backgroundColor}
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
      id={anchor}
    >
      <ContentWrapper>
        <h2>Kontaktskjema</h2>
        <Content>
          {!formStatus && (
            <>
              <div>
                <h3>{title}</h3>
                {intro}
              </div>
              <InputForm>
                {fields.map((field) => {
                  return (
                    <Input
                      key={field._key}
                      onLoad={() =>
                        setFormData({
                          ...formData,
                          [field._key]: {
                            value: false,
                            type: field.field,
                            label: field.text,
                          },
                        })
                      }
                      onChange={(val) =>
                        setFormData({
                          ...formData,
                          [field._key]: {
                            value: val.target.value,
                            type: field.field,
                            label: field.text,
                          },
                        })
                      }
                    >
                      <label>{field.text}</label>
                      {Field({ ...field })}
                    </Input>
                  )
                })}
                {formStatus === false && (
                  <div>
                    <H3>Vennligst fyll ut alle feltene</H3>
                  </div>
                )}
                <Button
                  onClick={async () => {
                    const success = await send(
                      receiver,
                      formData,
                      fields.length
                    )
                    setFormStatus(success)
                  }}
                >
                  SEND
                </Button>
              </InputForm>
            </>
          )}
          {formStatus === true && (
            <Thanks>
              <h3>
                Tusen takk for at du tar kontakt – du hører fra oss så fort som
                mulig
              </h3>
            </Thanks>
          )}
        </Content>
      </ContentWrapper>
    </Container>
  )
}

export default Form

const Thanks = styled.div`
  max-width: 400px;
`
const H3 = styled.div`
  margin-top: 15px;
  color: ${colors.green};
  ${arno(32)}
`

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.beige};` : ''}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  margin-top: 55px;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1366px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
  }
  min-width: 300px;
  h2 {
    margin-top: 0;
    flex: 30;
    ${arno(42)};
    color: ${colors.green};
    min-width: 300px;
  }
`

const Content = styled.div`
  display: flex;
  flex: 70;
  flex-direction: column;
  > div {
    h3 {
      margin-top: 6px;
      ${arno(30)}
      color: ${colors.green};
    }
    p {
      ${arno(20)}
      color: ${colors.green};
      max-width: 400px;
    }
  }
`
const InputForm = styled.form`
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
`

const Input = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  ${arno(20)}
  color: ${colors.green};
  textarea,
  input {
    border: 1px solid ${colors.lightGrey};
    ${arno(20)}
  }
  textarea {
    height: 250px;
    min-height: 100px;
    min-width: 100px;
    max-width: calc(100vw - 110px);
  }
  input {
    margin-bottom: 25px;
    height: 40px;
    width: 261px;
  }
`

const Button = styled.a`
  ${arno(18)}
  background-color: ${colors.beige}
    color: white;
  padding: 20px 30px;
  cursor: pointer;
  text-decoration: none;
  max-width: 250px;
  margin: 25px 0;
  text-align: center;
`
