import React, { useState } from 'react'
import styled from 'styled-components'
import { Image } from './partials/Image'
import { SanityParser } from './controllers/SanityParser'
import { useInView } from 'react-hook-inview'
import { colors, arno } from '../const'

const OurProducts = ({ proteinTypes, title, description }) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true })
  if (!proteinTypes) return null
  return (
    <Container
      className={'module module-event-list ' + (isVisible ? 'in-view' : '')}
      ref={ref}
    >
      <div className="wrapper">
        <div className="text-wrapper">
          <h2>{title}</h2>
          <p className="description">{description}</p>
        </div>
      </div>
      <OurProductsWrapper>
        {Object.values(proteinTypes).map(({ image, link, title }, i) => {
          const parsed = SanityParser({
            _type: 'proteinType',
            image,
            link,
          })
          return (
            <ProteinType
              key={i}
              as="a"
              href={parsed?.link || '#'}
            >
              <ImageWrapper>
                {parsed && (
                  <Image
                    alt={title}
                    src={parsed.image}
                    source={parsed.imageObj}
                    width={100}
                  />
                )}
              </ImageWrapper>
              <ProteinTypeInfo>{title}</ProteinTypeInfo>
            </ProteinType>
          )
        })}
      </OurProductsWrapper>
    </Container>
  )
}

export default OurProducts

export const Container = styled.div`
  background-color: ${colors.beige};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .wrapper {
    width: 100%;
    display: flex;
    max-width: 1366px;
    .text-wrapper {
      margin: 0 55px;
      padding-bottom: 50px;
      color: ${colors.green};
      h2 {
        ${arno(60)}
        margin-block-start: 0;
        margin-block-end: 0;
      }
      p {
        ${arno(18)}
        color: ${colors.green};
      }
      @media (max-width: 950px) {
        margin: 0 25px;
        h2 {
          ${arno(38)}
        }
        p {
          ${arno(16)}
        }
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  padding: 0;
  justify-content: center;
  align-items: flex-end;
  img {
    width: 100%;
    height: auto;
    max-width: 150px;
  }
`

const OurProductsWrapper = styled.div`
  max-width: 1366px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 25px 88px;
  a:last-of-type {
    border: none;
  }
  @media (max-width: 950px) {
    flex-wrap: wrap;
  }
`

const ProteinTypeInfo = styled.h3`
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
  align-self: center;
  ${arno(27)}
  color: ${colors.green}
`

const ProteinType = styled.div`
  ${(props) =>
    props.isActive
      ? `
		h3{
			text-decoration: underline;
		}`
      : ''}
  display: flex;
  flex-direction: column;
  flex: 1;
  text-decoration: none;
  justify-content: center;
  border-right: 1px dotted ${colors.lightGrey};
  @media (max-width: 950px) {
    width: 100%;
    min-width: 33.33%;
    ${ProteinTypeInfo} {
      margin-top: 10px;
    }
  }
`
