import React, { useEffect } from 'react'
import Routes from './Routes'
import MasterLayout from './components/layout/MasterLayout'
import { store } from './redux'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga4'

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GA)
    }
  }, [])

  return (
    <Provider store={store}>
      <MasterLayout>
        <Routes />
      </MasterLayout>
    </Provider>
  )
}

export default App
